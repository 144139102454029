import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";
import { BaseLink } from "scmp-app/components/common/base-link";

export const Container = styled.div`
  position: relative;

  display: block;

  padding-block-start: 4px;

  text-align: center;
`;

export const SectionName = styled.div`
  margin-block-start: 4px;

  color: #666666;
  font-size: 10px;
  font-family: ${fontRobotoCondensed};
  line-height: 12px;
`;

export const RedDotIcon = styled.span`
  position: absolute;
  inset-block-start: 0;
  inset-inline: auto -3px;
  z-index: 1;

  inline-size: 10px;
  block-size: 10px;

  border-radius: 10px;

  background-color: #eb4254;
`;

export const StyledBaseImage = styled(BaseImage)`
  inline-size: 64px;
  block-size: 92px;
`;

export const StyledBaseLink = styled(BaseLink)`
  border-radius: 4px;

  overflow: hidden;
`;
