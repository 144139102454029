/**
 * @generated SignedSource<<cc944c289ab1a658601d4f383e7ec739>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersStyleArticleListQuery$data = {
  readonly beauty: {
    readonly items: {
      readonly __typename: "QueueItemConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"itemStoryCardCarouselWidgetDisplayItemArticle" | "storyCardItemArticle">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"storyCardLargeLayoutQueueItemsEdge">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
    } | null | undefined;
  } | null | undefined;
  readonly fashion: {
    readonly items: {
      readonly __typename: "QueueItemConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"itemStoryCardCarouselWidgetDisplayItemArticle" | "storyCardItemArticle">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"storyCardLargeLayoutQueueItemsEdge">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
    } | null | undefined;
  } | null | undefined;
  readonly lifestyle: {
    readonly items: {
      readonly __typename: "QueueItemConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"itemStoryCardCarouselWidgetDisplayItemArticle" | "storyCardItemArticle">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"storyCardLargeLayoutQueueItemsEdge">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
    } | null | undefined;
  } | null | undefined;
  readonly luxury: {
    readonly items: {
      readonly __typename: "QueueItemConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"itemStoryCardCarouselWidgetDisplayItemArticle" | "storyCardItemArticle">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"storyCardLargeLayoutQueueItemsEdge">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
    } | null | undefined;
  } | null | undefined;
  readonly people: {
    readonly items: {
      readonly __typename: "QueueItemConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"itemStoryCardCarouselWidgetDisplayItemArticle" | "storyCardItemArticle">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"storyCardLargeLayoutQueueItemsEdge">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "helpersStyleArticleListQuery";
};
export type helpersStyleArticleListQuery$key = {
  readonly " $data"?: helpersStyleArticleListQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersStyleArticleListQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersStyleArticleListQuery"
};

(node as any).hash = "3c39bcda22666a560714c2528e52cd20";

export default node;
