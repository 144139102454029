/* eslint-disable relay/unused-fields */
import { atom } from "jotai";
import { graphql, readInlineData } from "react-relay";

import { currentItemAtom } from "scmp-app/lib/current-item";
import type { atomsCurrentStyleCardCarouselWidgetSectionsAtomBase$key } from "scmp-app/queries/__generated__/atomsCurrentStyleCardCarouselWidgetSectionsAtomBase.graphql";

export const currentStyleCardCarouselWidgetSectionsAtom = atom(get => {
  const currentItem = get(currentItemAtom);
  if (!currentItem) return [];

  const item = readInlineData<atomsCurrentStyleCardCarouselWidgetSectionsAtomBase$key>(
    graphql`
      fragment atomsCurrentStyleCardCarouselWidgetSectionsAtomBase on BaseWithApplicationAndUrlAlias
      @inline {
        __typename
        ... on Article {
          sections {
            value {
              entityId
              name
            }
          }
        }
        ... on Section {
          fullSectionPath {
            entityId
            name
          }
        }
      }
    `,
    currentItem,
  );

  switch (item.__typename) {
    case "Section":
      return item.fullSectionPath?.flatMap(s => [s]) ?? [];
    case "Article":
      if (!item.sections?.length) return [];
      return item.sections[0]?.value ?? [];
    default:
      return [];
  }
});
