/* eslint-disable react/no-multi-comp */
import { type FunctionComponent } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import type { sectionStyleStoryCardCarouselWidgetQuery } from "scmp-app/queries/__generated__/sectionStyleStoryCardCarouselWidgetQuery.graphql";

import { StoryCardCarouselWidgetDisplay } from "./display";

type Props = {
  onClose?: () => void;
};

const Component: FunctionComponent<Props> = props => {
  const content = useLazyLoadQuery<sectionStyleStoryCardCarouselWidgetQuery>(
    graphql`
      query sectionStyleStoryCardCarouselWidgetQuery {
        ...displayStoryCardCarouselWidgetQuery
      }
    `,
    {},
    { fetchPolicy: "store-or-network" },
  );

  return <StoryCardCarouselWidgetDisplay reference={content} {...props} />;
};

export const StoryCardCarouselWidget: FunctionComponent<Props> = props => (
  <ClientSideSuspense>
    <Component {...props} />
  </ClientSideSuspense>
);

StoryCardCarouselWidget.displayName = "StoryCardCarouselWidget";
