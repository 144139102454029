import type { FunctionComponent, ReactNode } from "react";

import { Portal } from "scmp-app/components/common/portal";
import { AfterPageLayoutContainerElementID } from "scmp-app/pages/_app/consts";

type Props = {
  children?: ReactNode;
};
export const AfterPageLayout: FunctionComponent<Props> = ({ children }) => (
  <Portal selector={`#${AfterPageLayoutContainerElementID}`}>{children}</Portal>
);

AfterPageLayout.displayName = "AfterPageLayout";
