import { theme, useResponsive } from "@product/scmp-sdk";
import { useAtom } from "jotai";
import first from "lodash/first";
import last from "lodash/last";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, readInlineData } from "react-relay";

import { getUniqueArticlesPerSection } from "scmp-app/components/magazines-style/helpers";
import { currentStyleCardCarouselWidgetSectionsAtom } from "scmp-app/components/section/section-style/section-style-story-card-carousel-widget/atoms";
import { useAcquisitionConfig } from "scmp-app/lib/app/hooks";
import type { displayStoryCardCarouselWidgetQuery$key } from "scmp-app/queries/__generated__/displayStoryCardCarouselWidgetQuery.graphql";

import { StoryCardCarouselWidgetDisplayItem } from "./item";
import { CarouselContainer, Container, ItemContainer, StyledLabelIcon, Wrapper } from "./styles";

type Props = {
  reference: displayStoryCardCarouselWidgetQuery$key;
};

export const StoryCardCarouselWidgetDisplay: FunctionComponent<Props> = ({ reference }) => {
  const content = readInlineData(
    graphql`
      fragment displayStoryCardCarouselWidgetQuery on Query @inline {
        ...helpersStyleArticleListQuery
      }
    `,
    reference,
  );

  const { acquisitionConfigState } = useAcquisitionConfig();

  const [sections] = useAtom(currentStyleCardCarouselWidgetSectionsAtom);
  const currentSectionEntityId = last(sections)?.entityId;

  const uniqueArticlesPerSection = useMemo(
    () =>
      currentSectionEntityId
        ? getUniqueArticlesPerSection({
            currentSectionEntityId,
            reference: content,
          })
        : { data: [] },
    [currentSectionEntityId, content],
  );

  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);

  if (
    !isMobile ||
    !uniqueArticlesPerSection ||
    !acquisitionConfigState?.result?.styleStoryCarousel.display
  )
    return null;

  return (
    <Container>
      <Wrapper>
        <StyledLabelIcon />
        <CarouselContainer>
          {uniqueArticlesPerSection.data.map(
            ({ articleEdges, isPublishedWithin24Hours, section }) => {
              const articleEdge = first(articleEdges);
              if (!articleEdge) return null;
              return (
                <ItemContainer key={articleEdge.node.entityId}>
                  <StoryCardCarouselWidgetDisplayItem
                    hasRedDot={isPublishedWithin24Hours}
                    pathname={section.pathname}
                    reference={articleEdge.node}
                    sectionName={section.name}
                  />
                </ItemContainer>
              );
            },
          )}
        </CarouselContainer>
      </Wrapper>
    </Container>
  );
};

StoryCardCarouselWidgetDisplay.displayName = "StoryCardCarouselWidgetDisplay";
